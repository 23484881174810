// extracted by mini-css-extract-plugin
export var linksContainer = "style-module--linksContainer--2eiFH";
export var mContainer = "style-module--mContainer--HvzA4";
export var linksLink = "style-module--linksLink--cvJCb";
export var linksLinkStatus = "style-module--linksLinkStatus--31kT5";
export var linksLinkContainer = "style-module--linksLinkContainer--UNyr7";
export var linksLinkActive = "style-module--linksLinkActive--12lPn";
export var linksLinkDisabled = "style-module--linksLinkDisabled--34lCB";
export var linksLinkTitle = "style-module--linksLinkTitle--1E3-f";
export var mInner = "style-module--mInner--tt6YH";
export var mItem = "style-module--mItem--3I6SW";
export var mBlock = "style-module--mBlock--1ORtz";
export var mTitle = "style-module--mTitle--HhaXo";
export var mTitleActive = "style-module--mTitleActive--1tmBh";
export var mDescription = "style-module--mDescription--1Yn5J";
export var menu = "style-module--menu--L-PyN";
export var menuLogo = "style-module--menuLogo--1kS61";
export var menuCardano = "style-module--menuCardano--1ZPrC";
export var menuSwitch = "style-module--menuSwitch--27JIU";
export var menuIcon = "style-module--menuIcon--9frE3";
export var submenu = "style-module--submenu--2YlER";
export var submenuLink = "style-module--submenuLink--3H_TF";
export var submenuLinkWidth = "style-module--submenuLinkWidth--2-GYM";
export var submenuLinkActive = "style-module--submenuLinkActive--1cu-T";