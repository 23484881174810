import React from "react"
import { Link } from "gatsby"
import { Tooltip } from "antd"
import { useDispatch, useSelector } from "react-redux"
import {
  SVGTada,
  SVGWallet,
  SVGCardano,
  SVGSun,
  SVGMoon,
  SVGTwitter,
  SVGAtSign,
  SVGChrome,
  SVGApple,
  SVGCategory,
  SVGAndroid,
  SVGInternet,
} from "@/svg"
import * as style from "./style.module.scss"
import imgWallet1 from "./wallet1.png"
import imgWallet2 from "./wallet2.png"

const MegaMenu = () => {
  const dispatch = useDispatch()
  const theme = useSelector((state) => state.settings.theme)

  const changeTheme = () => {
    dispatch({
      type: "settings/CHANGE_THEME",
      theme: theme === "default" ? "dark" : "default",
    })
  }

  const switchMegaMenu = () => {
    dispatch({
      type: "settings/SWITCH_MEGA_MENU",
    })
  }

  return (
    <div className={style.fixed}>
      <div className="tada__block mb-3">
        <div className={style.menu}>
          <Link to="/" className={`${style.menuLogo} me-4`}>
            <SVGTada />
            <span>
              <strong>TADATek</strong>
            </span>
          </Link>
          <span className="flex-grow-1 text-muted d-none d-sm-inline pe-2 pe-md-4">
            Powered with{" "}
            <span className={style.menuCardano}>
              <SVGCardano />
            </span>{" "}
            Cardano
          </span>
          <span className="ms-auto me-3 d-none d-sm-inline">
            <a
              // href="https://tadawallet.io"
              className="ant-btn tada__btn tada__btn--round"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="pe-2">TADAWallet</span>
              <span className="tada__icon">
                <SVGWallet />
              </span>
            </a>
          </span>
          <span
            className={`cursor-pointer me-3 ms-auto ms-sm-0 ${style.menuSwitch}`}
            onClick={changeTheme}
            onKeyPress={changeTheme}
            role="button"
            tabIndex="0"
          >
            <span className={theme === "default" ? "" : "d-none"}>
              <span className="tada__icon">
                <SVGSun />
              </span>
            </span>
            <span className={theme !== "default" ? "" : "d-none"}>
              <span className="tada__icon">
                <SVGMoon />
              </span>
            </span>
          </span>
          <span
            className={`${style.menuIcon} cursor-pointer`}
            onClick={switchMegaMenu}
            onKeyPress={switchMegaMenu}
            role="button"
            tabIndex="0"
            aria-label="Close Menu"
          />
        </div>
        <div className="tada__line mt-0" />
      </div>
      <div className="tada__block pt-3 mb-0">
        <div className="mb-5">
          <div>
            <div className="row">
              <div className="col-12 col-sm-8 mb-3">
                <div className={`${style.footerLists} row`}>
                  <div
                    className="col-6 col-sm-4"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="d-flex mb-4">
                      <span className="tada__icon me-2 mb-1 mb-sm-0">
                        <SVGWallet />
                      </span>
                      <h6 className="mb-0">
                        <strong>Cardano Solutions</strong>
                      </h6>
                    </div>
                    <ul className="list-unstyled mb-4">
                      <li>
                        <a
                          // href="https://tadatek.com/wallet/"
                          className="text-muted"
                        >
                          TADAWallet
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://tadatek.com/stake/"
                          className="text-muted"
                        >
                          TADAStake
                        </a>
                      </li>
                      {/* <li>
                        <a
                          href="https://tadatek.com/swap/"
                          className="text-muted"
                        >
                          TADASwap
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://tadatek.com/kickstart/"
                          className="text-muted"
                        >
                          TADAKickstart
                        </a>
                      </li> */}
                      <li>
                        <a
                          // href="https://tadatek.com/nft/"
                          className="text-muted"
                        >
                          TADANFT
                        </a>
                      </li>
                      {/* <li>
                        <a
                          href="https://tadatek.com/graph/"
                          className="text-muted"
                        >
                          TADAGraph
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://tadatek.com/data/"
                          className="text-muted"
                        >
                          TADAData
                        </a>
                      </li> */}
                      {/* <li>
                        <a href="https://minterr.io/" className="text-muted">
                          Minterr.io
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://github.com/tadatek/cardano-web3.js"
                          className="text-muted"
                        >
                          CardanoWeb3.js
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://tadatek.com/cardano-list/"
                          className="text-muted"
                        >
                          Cardano List
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  <div
                    className="col-6 col-sm-4"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <div className="d-flex mb-4">
                      <span className="tada__icon me-2 mb-1 mb-sm-0">
                        <SVGAtSign />
                      </span>
                      <h6 className="mb-0">
                        <strong>Information</strong>
                      </h6>
                    </div>
                    <ul className="list-unstyled mb-5">
                      <li>
                        <a
                          href="https://tadatek.com/tada/"
                          className="text-muted"
                        >
                          TADA Token
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://tadatek.com/vni/"
                          className="text-muted"
                        >
                          VNI
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://tadatek.com/roadmap/"
                          className="text-muted"
                        >
                          Roadmap & Updates
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://tadatek.com/wiki/"
                          className="text-muted"
                        >
                          Wiki
                        </a>
                      </li>
                      {/* <li>
                        <a
                          href="https://status.tadatek.com/"
                          className="text-muted"
                        >
                          Status
                        </a>
                      </li> */}
                      <li>
                        <a
                          href="https://tadatek.com/about/"
                          className="text-muted"
                        >
                          About
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://tadatek.com/whitepaper/"
                          className="text-muted"
                        >
                          Whitepaper
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://docs.tadatek.com/"
                          className="text-muted"
                        >
                          Docs
                        </a>
                      </li>
                      {/* <li>
                        <a
                          href="https://tadatek.com/audit/"
                          className="text-muted"
                        >
                          Audit
                        </a>
                      </li> */}
                      <li>
                        <a
                          href="https://tadatek.com/terms-of-use/"
                          className="text-muted"
                        >
                          Terms of Use
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="col-12 col-sm-4"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <div className="row">
                      <div className="col-6 col-sm-12">
                        <div className="d-flex mb-4">
                          <span className="tada__icon me-2 mb-1 mb-sm-0">
                            <SVGTwitter />
                          </span>
                          <h6 className="mb-0">
                            <strong>TADATek</strong>
                          </h6>
                        </div>
                        <ul className="list-unstyled mb-5">
                          <li>
                            <a
                              href="https://twitter.com/tadatek"
                              className="text-muted"
                            >
                              Twitter
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://t.me/congdongcardano"
                              className="text-muted"
                            >
                              Telegram Chat
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://t.me/tadatek"
                              className="text-muted"
                            >
                              Telegram
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://discord.gg/"
                              className="text-muted"
                            >
                              Discord
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.reddit.com/r/tadatek"
                              className="text-muted"
                            >
                              Reddit
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* <div className="col-6 col-sm-12">
                        <div className="d-flex mb-4">
                          <span className="tada__icon me-2 mb-1 mb-sm-0">
                            <SVGTwitter />
                          </span>
                          <h6 className="mb-0">
                            <strong>Minterr.io</strong>
                          </h6>
                        </div>
                        <ul className="list-unstyled mb-4">
                          <li>
                            <a
                              href="https://twitter.com/MinterrApp"
                              className="text-muted"
                            >
                              Twitter
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://discord.gg/dDVXcthYWn"
                              className="text-muted"
                            >
                              Discord
                            </a>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-up" data-aos-delay="900">
            {/* <div className="col-12 col-md-6">
              <div className="row">
                <div className="col-6 col-md-12">
                  <div className="mb-3">
                    <h6 className="mb-0">
                      <strong>TADAWallet Apps</strong>
                    </h6>
                  </div>
                  <div className="mb-4">
                    <div>
                      <Tooltip title="Web Version">
                        <a
                          href="https://tadawallet.io"
                          className={style.footerApp}
                        >
                          <span className="tada__icon tada__icon--22">
                            <SVGInternet />
                          </span>
                        </a>
                      </Tooltip>
                      <Tooltip title="Chrome Extension">
                        <a
                          href="https://rraayy.com/wallet/"
                          className={style.footerApp}
                        >
                          <span className="tada__icon tada__icon--22">
                            <SVGChrome />
                          </span>
                        </a>
                      </Tooltip>
                      <Tooltip title="macOS App">
                        <a
                          href="https://rraayy.com/wallet/"
                          className={style.footerApp}
                        >
                          <span className="tada__icon tada__icon--22">
                            <SVGApple />
                          </span>
                        </a>
                      </Tooltip>
                      <Tooltip title="Windows App">
                        <a
                          href="https://rraayy.com/wallet/"
                          className={style.footerApp}
                        >
                          <span className="tada__icon tada__icon--22">
                            <SVGCategory />
                          </span>
                        </a>
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip title="iOS App">
                        <a
                          href="https://rraayy.com/wallet/"
                          className={style.footerApp}
                        >
                          <span className="tada__icon tada__icon--22">
                            <SVGApple />
                          </span>
                        </a>
                      </Tooltip>
                      <Tooltip title="Android App">
                        <a
                          href="https://rraayy.com/wallet/"
                          className={style.footerApp}
                        >
                          <span className="tada__icon tada__icon--22">
                            <SVGAndroid />
                          </span>
                        </a>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-12">
                  <div className="mb-3">
                    <h6 className="mb-0">
                      <strong>TADAStake Apps</strong>
                    </h6>
                  </div>
                  <div className="mb-4">
                    <div>
                      <Tooltip title="Web Version">
                        <a
                          href="https://rraayy.com/stake/track/"
                          className={style.footerApp}
                        >
                          <span className="tada__icon tada__icon--22">
                            <SVGInternet />
                          </span>
                        </a>
                      </Tooltip>
                      <Tooltip title="iOS App">
                        <a
                          href="https://rraayy.com/stake/track/"
                          className={style.footerApp}
                        >
                          <span className="tada__icon tada__icon--22">
                            <SVGApple />
                          </span>
                        </a>
                      </Tooltip>
                      <Tooltip title="Android App">
                        <a
                          href="https://rraayy.com/stake/track/"
                          className={style.footerApp}
                        >
                          <span className="tada__icon tada__icon--22">
                            <SVGAndroid />
                          </span>
                        </a>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-12 col-md-6">
              <div className={style.wallets}>
                <img src={imgWallet2} alt="TADAWallet" />
                <img src={imgWallet1} alt="TADAWallet" />
              </div>
            </div> */}
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 text-muted">
              Advanced Ecosystem for Cardano Blockchain Platform
            </p>
            <p className="mb-0 text-muted">
              {new Date().getFullYear()} &copy; TADATek
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MegaMenu
