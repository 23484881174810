import React from "react"
import { useSelector } from "react-redux"
import { Input, Tooltip } from "antd"
import {
  SVGTada,
  SVGCardano,
  SVGWallet,
  SVGTwitter,
  SVGAtSign,
  SVGChrome,
  SVGApple,
  SVGCategory,
  SVGAndroid,
  SVGInternet,
} from "@/svg"
import * as style from "./style.module.scss"

const Footer = () => {
  const networkState = useSelector((state) => state.settings.networkState)
  console.log(networkState)

  return (
    <div className={style.outer}>
      <div className="tada__block mb-0">
        <div className="pb-5">
          <div className={style.footerTop}>
            <div className="row">
              <div className="col-12 col-sm-8 mb-3">
                <div className={`${style.footerLists} row`}>
                  <div className="col-6 col-sm-4 ">
                    <div className="d-flex mb-4">
                      <span className="tada__icon me-2 mb-1 mb-sm-0">
                        <SVGWallet />
                      </span>
                      <h6 className="mb-0">
                        <strong>Cardano Solutions</strong>
                      </h6>
                    </div>
                    <ul className="list-unstyled mb-4">
                      <li>
                        <a
                          // href="https://tadatek.com/wallet/"
                          className="text-muted"
                        >
                          TADAWallet
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://tadatek.com/stake/"
                          className="text-muted"
                        >
                          TADAStake
                        </a>
                      </li>
                      <li>
                        <a
                          // href="https://tadatek.com/swap/"
                          className="text-muted"
                        >
                          TADASwap
                        </a>
                      </li>
                      {/* <li>
                        <a
                          href="https://tadatek.com/kickstart/"
                          className="text-muted"
                        >
                          TADAKickstart
                        </a>
                      </li> */}
                      <li>
                        <a
                          // href="https://tadatek.com/nft/"
                          className="text-muted"
                        >
                          TADANFT
                        </a>
                      </li>
                      {/* <li>
                        <a
                          href="https://tadatek.com/graph/"
                          className="text-muted"
                        >
                          TADAGraph
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://tadatek.com/data/"
                          className="text-muted"
                        >
                          TADAData
                        </a>
                      </li> */}
                      {/* <li>
                        <a href="https://minterr.io/" className="text-muted">
                          Minterr.io
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://github.com/ray-network/cardano-web3.js"
                          className="text-muted"
                        >
                          CardanoWeb3.js
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://tadatek.com/cardano-list/"
                          className="text-muted"
                        >
                          Cardano List
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  <div className="col-6 col-sm-4">
                    <div className="d-flex mb-4">
                      <span className="tada__icon me-2 mb-1 mb-sm-0">
                        <SVGAtSign />
                      </span>
                      <h6 className="mb-0">
                        <strong>Information</strong>
                      </h6>
                    </div>
                    <ul className="list-unstyled mb-5">
                      <li>
                        <a
                          href="https://tadatek.com/tada/"
                          className="text-muted"
                        >
                          TADA Token
                        </a>
                      </li>
                      {/* <li>
                        <a
                          href="https://tadatek.com/vni/"
                          className="text-muted"
                        >
                          VNI
                        </a>
                      </li> */}
                      {/* <li>
                        <a
                          href="https://tadatek.com/roadmap/"
                          className="text-muted"
                        >
                          Roadmap & Updates
                        </a>
                      </li> */}
                      <li>
                        <a
                          href="https://tadatek.com/wiki/"
                          className="text-muted"
                        >
                          Wiki
                        </a>
                      </li>
                      {/* <li>
                        <a
                          href="https://status.tadatek.com/"
                          className="text-muted"
                        >
                          Status
                        </a>
                      </li> */}
                      <li>
                        <a
                          href="https://tadatek.com/about/"
                          className="text-muted"
                        >
                          About
                        </a>
                      </li>
                      {/* <li>
                        <a
                          href="https://docs.tadatek.com/whitepaper/"
                          className="text-muted"
                        >
                          Whitepaper
                        </a>
                      </li> */}
                      <li>
                        <a
                          href="https://docs.tadatek.com/"
                          className="text-muted"
                        >
                          Docs
                        </a>
                      </li>
                      {/* <li>
                        <a
                          href="https://tadatek.com/audit/"
                          className="text-muted"
                        >
                          Audit
                        </a>
                      </li> */}
                      <li>
                        <a
                          href="https://tadatek.com/terms-of-use/"
                          className="text-muted"
                        >
                          Terms of Use
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="row">
                      <div className="col-6 col-sm-12">
                        <div className="d-flex mb-4">
                          <span className="tada__icon me-2 mb-1 mb-sm-0">
                            <SVGTwitter />
                          </span>
                          <h6 className="mb-0">
                            <strong>TADATek</strong>
                          </h6>
                        </div>
                        <ul className="list-unstyled mb-5">
                          <li>
                            <a
                              href="https://twitter.com/TadaTek"
                              className="text-muted"
                            >
                              Twitter
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://t.me/congdongcardano"
                              className="text-muted"
                            >
                              Telegram Chat
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://t.me/tadatek"
                              className="text-muted"
                            >
                              Telegram
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://discord.gg/VR2sBGTNDU"
                              className="text-muted"
                            >
                              Discord
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.reddit.com/r/tadatek"
                              className="text-muted"
                            >
                              Reddit
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* <div className="col-6 col-sm-12">
                        <div className="d-flex mb-4">
                          <span className="tada__icon me-2 mb-1 mb-sm-0">
                            <SVGTwitter />
                          </span>
                          <h6 className="mb-0">
                            <strong>Minterr.io</strong>
                          </h6>
                        </div>
                        <ul className="list-unstyled mb-4">
                          <li>
                            <a
                              href="https://twitter.com/MinterrApp"
                              className="text-muted"
                            >
                              Twitter
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://discord.gg/dDVXcthYWn"
                              className="text-muted"
                            >
                              Discord
                            </a>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4">
                {/* <div className="row">
                  <div className="col-6 col-sm-12">
                    <div className="mb-3">
                      <h6 className="mb-0">
                        <strong>RayWallet Apps</strong>
                      </h6>
                    </div>
                    <div className="mb-4">
                      <div>
                        <Tooltip title="Web Version">
                          <a
                            href="https://raywallet.io"
                            className={style.footerApp}
                          >
                            <span className="tada__icon tada__icon--22">
                              <SVGInternet />
                            </span>
                          </a>
                        </Tooltip>
                        <Tooltip title="Chrome Extension">
                          <a
                            href="https://rraayy.com/wallet/"
                            className={style.footerApp}
                          >
                            <span className="tada__icon tada__icon--22">
                              <SVGChrome />
                            </span>
                          </a>
                        </Tooltip>
                        <Tooltip title="macOS App">
                          <a
                            href="https://rraayy.com/wallet/"
                            className={style.footerApp}
                          >
                            <span className="tada__icon tada__icon--22">
                              <SVGApple />
                            </span>
                          </a>
                        </Tooltip>
                        <Tooltip title="Windows App">
                          <a
                            href="https://rraayy.com/wallet/"
                            className={style.footerApp}
                          >
                            <span className="tada__icon tada__icon--22">
                              <SVGCategory />
                            </span>
                          </a>
                        </Tooltip>
                      </div>
                      <div>
                        <Tooltip title="iOS App">
                          <a
                            href="https://rraayy.com/wallet/"
                            className={style.footerApp}
                          >
                            <span className="tada__icon tada__icon--22">
                              <SVGApple />
                            </span>
                          </a>
                        </Tooltip>
                        <Tooltip title="Android App">
                          <a
                            href="https://rraayy.com/wallet/"
                            className={style.footerApp}
                          >
                            <span className="tada__icon tada__icon--22">
                              <SVGAndroid />
                            </span>
                          </a>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-sm-12">
                    <div className="mb-3">
                      <h6 className="mb-0">
                        <strong>RayStake Apps</strong>
                      </h6>
                    </div>
                    <div className="mb-4">
                      <div>
                        <Tooltip title="Web Version">
                          <a
                            href="https://rraayy.com/stake/track/"
                            className={style.footerApp}
                          >
                            <span className="tada__icon tada__icon--22">
                              <SVGInternet />
                            </span>
                          </a>
                        </Tooltip>
                        <Tooltip title="iOS App">
                          <a
                            href="https://rraayy.com/stake/track/"
                            className={style.footerApp}
                          >
                            <span className="tada__icon tada__icon--22">
                              <SVGApple />
                            </span>
                          </a>
                        </Tooltip>
                        <Tooltip title="Android App">
                          <a
                            href="https://rraayy.com/stake/track/"
                            className={style.footerApp}
                          >
                            <span className="tada__icon tada__icon--22">
                              <SVGAndroid />
                            </span>
                          </a>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="mb-3">
                  <h6 className="mb-0">
                    <strong>Newsletters</strong>
                  </h6>
                </div>
                <div className="mb-4">
                  <Input.Search
                    placeholder="Enter your email"
                    allowClear
                    enterButton="Subscribe"
                    size="large"
                    onSearch={() => {}}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="mb-2">
              <a
                className={style.footerRay}
                href="https://tadatek.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <SVGTada />
                <span>
                  <strong>TADATek</strong>
                </span>
              </a>
            </div>
            <p className="mb-2 text-muted">
              Advanced Ecosystem for Cardano Blockchain Platform.{" "}
              <span>
                <span>Powered with</span>{" "}
                <a
                  href="https://cardano.org/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span className={style.footerCardano}>
                    <SVGCardano />
                  </span>
                  <strong>Cardano</strong>
                </a>
              </span>
            </p>
            <p className="mb-2 text-muted">
              <span className="me-2 text-capitalize">
                Cardano Mainnet Status: Epoch{" "}
                {networkState?.currentEpoch?.number || 0}, Block{" "}
                {networkState?.tip?.number || 0}, Slot{" "}
                {networkState?.tip?.slotNo || 0}
              </span>
            </p>
            <p className="mb-0 text-muted">
              {new Date().getFullYear()} &copy; TADATek
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
