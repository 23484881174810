// extracted by mini-css-extract-plugin
export var wallets = "style-module--wallets--12dk9";
export var fixed = "style-module--fixed--2A4fd";
export var menu = "style-module--menu--2EhNz";
export var menuLogo = "style-module--menuLogo--30VQ_";
export var menuCardano = "style-module--menuCardano--_cARo";
export var menuSwitch = "style-module--menuSwitch--3MZ2V";
export var menuIcon = "style-module--menuIcon--1a6y4";
export var footerLists = "style-module--footerLists--3vHKY";
export var footerRay = "style-module--footerRay--2KUrW";
export var footerCardano = "style-module--footerCardano--IRmrM";
export var footerApp = "style-module--footerApp--3napf";